jQuery(document).ready(function ($) {

    //setTimeout(_ => $('body').addClass('-pre-menu-ready'), 3000 )

    const navbar_menu = $('.site-navbar__menu-list');

    const dropdown_menus = navbar_menu.find('.dropdown-menu');
    const hamburger_toggle = $('.hamburger-toggle');

    const remove_open = function() {
        return this.find('.dropdown.open').removeClass('open');
    };

    const is_mobile_view = () => window.innerWidth <= 991;

    $('body').on('click', `${navbar_menu.selector} a`, function (e) { createRipple.call(this, e) });

    ;( async function () {

        navbar_menu.find('.dropdown').each( function () {
            const $current_dropdown = $(this);
            const $link = $current_dropdown.find('> a');
            const $dropdown_menu = $current_dropdown.find('>.dropdown-menu');
            $dropdown_menu.prepend(`<li class="-pre-menu-item--headline"><a href="#">${$link.text()}</a></li>`);
        });

        $('body').on('click', '.-pre-menu-item--headline > a', function (e) {
            e.preventDefault();
            $(this).closest('.dropdown').removeClass('open');
        });

        hamburger_toggle.on('click', function (e) {
            e.preventDefault();
            hamburger_toggle.toggleClass('-pre-hamburger-toggle--active');
            $('body').toggleClass('-pre-menu-open');
            remove_open.call(navbar_menu);
        });

    })();


    navbar_menu.find('.dropdown > a').on('click', function (e) {
        e.preventDefault();
        const $this = $(this);
        const $current_dropdown = $(this).parent();
        const $current_dropdown_parent = $current_dropdown.parent();
        const $current_dropdown_menu = $current_dropdown.find(' > .dropdown-menu');
        const _remove_open = () => remove_open.call($current_dropdown_parent);
        const has_open = $current_dropdown.hasClass('open');
        if( $current_dropdown_parent.attr('id') === navbar_menu.attr('id') ){
            if( has_open ) {
                remove_open.call(navbar_menu);
            }
            else {
                const element_width = $this.outerWidth();
                const current_dropdown_menu_width = $current_dropdown_menu.outerWidth();
                !is_mobile_view() && $current_dropdown_menu.css('left', `${ ( $this.offset().left - current_dropdown_menu_width ) + element_width  }px` );
            }
        }

        if( has_open ) {
            $current_dropdown.removeClass('open');
            _remove_open();
        }
        else  {
            _remove_open();
            $current_dropdown.addClass('open');
        }
    });

    const close_mobile_menu = function () {
        hamburger_toggle.removeClass('-pre-hamburger-toggle--active');
        $('body').removeClass('-pre-menu-open');
        remove_open.call(navbar_menu);
    };

    // Close menu if clicked outside of the menu container
    $(document).mouseup(function (e) {
        if ( !navbar_menu.is(e.target) // if the target of the click isn't the container...
            && navbar_menu.has(e.target).length === 0 // ... nor a descendant of the container
            && e.which === 1 && !is_mobile_view() ) {
            e.preventDefault();
            remove_open.call(navbar_menu);
        }
    });

    $('body').on('click touchend', '#page-content > .site-overlay', e => is_mobile_view() && close_mobile_menu() );

    $(window).on('orientationchange', (event) => {
        dropdown_menus.css('display', 'none');
        setTimeout( () => close_mobile_menu() , 0 );
    });
});
