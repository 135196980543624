jQuery(document).ready(function ($) {
    "use strict";
    ;( function () {

        let settings = {
            pager: false, // Enable/Disable pager
            zoom: false, // Enable/Disable zoom option
            autoplayControls: false, // Show/hide autoplay controls.
            download: false, // Enable download button. By default download url will be taken from data-src/href attribute but it supports only for modern browsers. If you want you can provide another url for download via data-download-url. Pass false in data-download-url if you want to hide download button for the particular slide.
            thumbnail:false,
            animateThumb: false,
            showThumbByDefault: false
        };

        const lightgallery = $('.flexible-video-gallery');

        if( lightgallery.length > 0 ) {
            lightgallery.each( function () {
                const $this_gallery = $(this);
                const gallery_id = $this_gallery.attr('id');
                let selector = gallery_id ? `#${gallery_id}` : '';
                selector += `${lightgallery.selector} .about-video__toggle`;
                settings = { ...settings, selector } ; // Custom selector property instead of just child. pass this to select same element ex : .class #id
                //console.log( settings );
                $this_gallery.lightGallery(settings);
                /**
                 * Note: true - If you pass true as a parameter destroy method will destroy the gallery completely.
                 *      If parameter is undefined or false destroy will only close the gallery and plugins instance remains with the element.
                 */
                $('body').on('click touchstart', '.lg-close', () => $this_gallery.data('lightGallery').destroy(false) );
            })
        }

        {
            const $about_video = $('.about-video');

            settings['selector'] = `${$about_video.selector} .about-video__toggle`;

            $about_video.lightGallery(settings);

            $about_video.hover(
                function() {
                    $( this ).addClass('-pre-hover');
                }, function() {
                    $( this ).removeClass('-pre-hover');
                }
            );

            $about_video.on('click', function () {
                $(this).find('.about-video__toggle').triggerHandler('click');
            })
            /**
             * Note: true - If you pass true as a parameter destroy method will destroy the gallery completely.
             *      If parameter is undefined or false destroy will only close the gallery and plugins instance remains with the element.
             */
            $('body').on('click touchstart', '.lg-close', () => $about_video.data('lightGallery').destroy(false) );
        }


    })();
});