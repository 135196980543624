jQuery(document).ready(function ($) {
    "use strict";

    const selectors = '.ginput_container_phone input, .ginput_container_email input';

    $('body').on('change', selectors, function (e) {
        const $this = $(this);
        const $parent = $this.parent();
        const value = $this.val().trim();

        const parent_class_selector = 'ginput_container--ltr-notempty';

        $parent.removeClass(parent_class_selector);
        value.length && $parent.addClass(parent_class_selector);
    })
        .on('focusin', selectors, function (e) {
            $(this).parent().addClass('ginput_container--ltr-focusin');
        })
        .on('focusout', selectors, function () {
            $(this).parent().removeClass('ginput_container--ltr-focusin');
        })

});