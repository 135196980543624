jQuery(document).ready(function ($) {

    const members_grid = $('.members-grid');
    const get_members_list = () => members_grid.find('.members-grid__grid');
    const members_list = get_members_list();
    const members_template = members_grid.find('.members-grid__template');

    const remove_active = () => members_list.find('li.-pre-active').removeClass('-pre-active');

    $(window).on('orientationchange', () => {
        const $col_about = get_members_list().find('.members-grid__grid-col--about');
        if( $col_about.length ){
            setTimeout( function () {
                remove_active();
                $col_about.remove()
            }, 0)
        }
    } );

    /**
     * @param int member_id - Will be available only if the user have come from the front page. Holds the member ID
     */
    members_list.find('.member').on('click', function (e, member_id) {
        e.preventDefault();

        const $member   = $(this);
        const $parent = $member.parent();
        const member_ID = $parent.attr('data-member_id');

        const members_list = $member.closest('.members-grid__grid');

        const _get_col_about = () => members_list.find('.members-grid__grid-col--about');

        const _is_mobile = () => window.innerWidth <= 991;

        if( $parent.hasClass('-pre-active') ) {

            _get_col_about().slideUp('slow', function () {
                $(this).remove();
                $parent.removeClass('-pre-active');
            });
            return;
        }

        const init = function() {
            remove_active();
            const position = $parent.index(); // The parent index, must be call after remove .portfolio-box parent
            const $lastItem = members_list.find('> li:last-child'); // Last col parent, must be call after remove .portfolio-box parent

            let inRow = 1; // xs
            if( window.innerWidth >= 768 ) inRow = 2;
            if( window.innerWidth >= 992 ) inRow = 3;
            if( window.innerWidth >= 1200 ) inRow = 4;
            if( window.innerWidth >= 1600 && !$member.closest('.flexible-members').length ) inRow = 5;

            const itemPosition = inRow - (position % inRow) - 1;

            // Get the active parent col
            const getActiveItem = () => {
                let _item;
                if( itemPosition === 0 )
                    return $parent;
                _item = $parent.nextAll().slice(0, itemPosition).last();
                if( _item.length === 0 || $lastItem.index() === position )
                    _item = $lastItem;
                return _item
            };

            const insert_output = (output) => {
                $(`<li class="members-grid__grid-col--about" style="display: none;">${output.get(0).outerHTML}</li>`).
                insertAfter( getActiveItem() ).
                promise().
                done(  _ => {

                    const _col_slide_down = (fb) => members_list.find('li.members-grid__grid-col--about').slideDown('slow', fb);

                    if( member_id ) {
                        _col_slide_down();
                    }
                    else {
                        if( _is_mobile() ) {

                            const $site_navbar = $('.site-navbar');
                            const navbar_height = $site_navbar.innerHeight();

                            $('html, body').animate({ scrollTop: $member.offset().top - navbar_height }, 500 ).
                            promise().
                            done( () => _col_slide_down() );

                        }
                        else {
                            _col_slide_down();
                        }
                    }



                        //const $grid_about = $(this).find('.members-grid__about');
                        //window.innerWidth >= 992 && $grid_about.focus();
                } );
            };

            const output = members_template.find(`.members-grid__about[data-member_id="${member_ID}"]`).clone(false);

            insert_output( output );
            $parent.addClass('-pre-active');

        };

        const $col_about = _get_col_about();

        const _remove_and_init = function () {
            this.remove();
            init();
        };

        if( member_id ) {
            init();
        }
        else {
            if( $col_about.length ) {
                if( _is_mobile() ) {
                    _remove_and_init.call($col_about);
                }
                else {
                    $col_about.slideUp('slow', function () {
                        _remove_and_init.call( $(this) );
                    });
                }
            }
            else {
                _remove_and_init.call($col_about);
            }
        }


    });
});
