jQuery(document).ready(function ($) {
    ;( function () {

        const $slider = $('.workshop');
        const options = {
            mobileFirst: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            //variableWidth: true,
            dots: false,
            centerMode: true,
            //focusOnSelect: true,
            infinite: true,
            rtl: dtheatro_is_rtl(),
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 991,
                    settings: 'unslick'
                },
            ]
        };

        const slick_initialized = () => $slider.hasClass('slick-initialized');

        //window.innerWidth <= 991 && $slider.slick( options );

        //$slider.slick( options );
        const resize = () => {
            //slick_initialized() && $slider.slick('unslick');
            //setTimeout(() => $slider.slick(options), 100);
        };

        //$(window).on('resize orientationchange', resize );

    })();
});