jQuery(document).ready(function ($) {
    "use strict";

    const FormAlert = ( alert ) => {

        const options = {
            type: 'danger',
            data: '',
            dismissible: false
        };

        alert = jQuery.extend({}, options, alert);

        if( jQuery.isArray( alert.data ) && alert.data.length > 0 )
            alert.data = `<ul>${alert.data.map( item => `<li>${ item }</li>`) }</ul>`;

        return (
            alert.data.trim() && `<div class="alert alert-${alert.type} ${ alert.dismissible ? 'fade in' : '' }">${ alert.data }</div>`
        );
    };

    ;( function () {

        const $lobby_form = $('.lobby__login-form');

        $lobby_form.validator().on('submit', function (e) {
            const $this_form = $(this);
            const $lobby_box = $this_form.parent();
            const data = $this_form.serializeControls();
            const $submit = $this_form.find('[type="submit"]');

            $lobby_box.find('.alert').remove();

            if ( e.isDefaultPrevented() ) {

                // handle the invalid form...
                console.log('Handle the invalid form');
            }

            else {

                const error = ( jqXHR ) => {

                    const data = 'Error please contact administrator.';

                    $lobby_box.prepend( FormAlert( {data} ) );

                    console.error( jqXHR );
                };

                const success = ( response ) => {

                    const { data } = response;

                    if( response['success'] ) {
                        const type = 'success';

                        //$lobby_box.prepend( FormAlert( {data, type} ) );

                        window.location.reload();

                        return true;
                    }

                    $lobby_box.prepend( FormAlert( {data} ) );

                };

                jQuery.ajax({
                    data,
                    error,
                    success,
                    'url': dtheatro_get_ajax_url(),
                    'method': 'POST', // Should use type if you're using versions of jQuery prior to 1.9.0.
                    'cache': false
                });
            }
            e.preventDefault();
        });

    })();

});