jQuery(document).ready(function ($) {
    ;( function () {
        const $slider = $('.members-slider__row');
        const options = {
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            speed: 300,
            //variableWidth: true,
            dots: false,
            centerMode: true,
            //focusOnSelect: false,
            useTransform:false,
            infinite: true,
            rtl: dtheatro_is_rtl(),
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 5,
                        centerMode: false,
                    }
                },
            ]
        };

        $slider.on('init', () => $('.member > .member__caption').css('position', 'absolute') );

        $slider.slick(options);

        $('.members-slider__botton').on('click', function(e){

            const botton = $(this);
            const slide = botton.data('slide');
            $('.members-slider').find('.slick-slider').slick(slide);
        });

    })();
});