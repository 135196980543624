jQuery(document).ready(function ($) {
    //;( () => jQuery('.site-footer__menu-list').animate({scrollLeft: 50}, 0) )();

    $('.flexible-accordion__panel-title > a').hover(
        function() {
            //$( this ).find('.flexible-accordion__panel-icon').addClass('-pre-animation-panel-icon');
        }, function() {
            //$( this ).find('.flexible-accordion__panel-icon').removeClass('-pre-animation-panel-icon');
        }
    );

});