function getTop() {
    return jQuery(window).scrollTop();
}

function createRipple (e) {

    // Create new ripple element
    const ripple = document.createElement('div');

    // Append child element
    this.appendChild(ripple);

    // The inner width of an element in pixels.
    // It includes padding but excludes borders, margins, and vertical scrollbars
    const btnWidth = this.clientWidth;
    const btnHeight = this.clientHeight;

    // Returns the largest of zero or more numbers
    const dimension = Math.max(btnWidth, btnHeight);

    // Because our buttons are not square but rectangels we need to set their width and height to be same
    ripple.style.width = ripple.style.height = dimension + 'px';

    // Returns the size of an element and its position relative to the viewport
    const rect = this.getBoundingClientRect();

    // Holds the coordinates of the mouse pointer when the mouse button is clicked
    const clickedPosition = {
        x: e.clientX,
        y: e.clientY
    };

    // Set Ripple X Y edge position
    ripple.style.left = (clickedPosition.x - rect.left) - (dimension/2) + 'px';
    ripple.style.top = (clickedPosition.y - rect.top) - (dimension/2) + 'px';

    // Add .ripple class to create ripple animation
    ripple.classList.add('-pre-ripple');

    // Remove Ripple from his parent element
    setTimeout( () => this.removeChild(ripple) , 600 );
}

;( function ($) {

    $.fn.doSlickSlide = function ( direction ) {
        var self = $(this),
            slickSection = self.closest('.slick-section');
        if( slickSection.length > 0 )
            slickSection.find('.slick-slider').slick(direction);
        return this;
    };

    $('.slick-next').on('click', function(){
        $(this).doSlickSlide('slickNext');
    });

    $('.slick-prev').on('click', function(){
        $(this).doSlickSlide('slickPrev');
    });

    $.fn.serializeControls = function() {
        let data = {};

        function buildInputObject(arr, val) {
            if (arr.length < 1)
                return val;
            let objkey = arr[0];
            if (objkey.slice(-1) === "]") {
                objkey = objkey.slice(0,-1);
            }
            let result = {};
            if (arr.length === 1){
                result[objkey] = val;
            } else {
                arr.shift();
                let nestedVal = buildInputObject(arr,val);
                result[objkey] = nestedVal;
            }
            return result;
        }

        $.each(this.serializeArray(), function() {
            let val = this.value;
            let c = this.name.split("[");
            let a = buildInputObject(c, val);
            $.extend(true, data, a);
        });

        return data;
    };

})(jQuery);


