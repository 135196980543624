jQuery(document).ready(function ($) {
    ;( function () {
        const $slider = $('.clients__slider');
        const options = {
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            useTransform:false,
            infinite: true,
            fade: false,
            rtl: dtheatro_is_rtl(),
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        fade: true,
                    }
                }
            ]
        };

        $slider.slick(options);

        $('.clients__botton').on('click', function(e){
            const botton = $(this);
            const slide = botton.data('slide');
            $('.clients').find('.slick-slider').slick(slide);
        });

        const $logos_list = $('.clients__logos-list');

        $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            const $slick_slide = $slider.find(`[data-slick-index="${nextSlide}"]`);
            const $article = $slick_slide.find('.clients__client');
            const client = $article.attr('data-client');
            $logos_list.find('> li').removeClass('active');
            $logos_list.find(`.clients__logo[data-client="${client}"]`).parent().addClass('active');
        });

        $('.clients__logo').on('click', function (e) {
            e.preventDefault();
            const $button = $(this);
            const client = $button.attr('data-client');
            $logos_list.find('> li').removeClass('active');
            $button.parent().addClass('active');
            const index = $slider.find(`.clients__client[data-client="${client}"]`).closest('.slick-slide').attr('data-slick-index');
            $slider.slick('slickGoTo', index);
        })

    })();
});