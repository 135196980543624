jQuery(document).ready(function ($) {
    ;( function () {
        const $slider = $('.flexible-members__row');
        const options = {
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            //variableWidth: true,
            dots: false,
            centerMode: true,
            //focusOnSelect: false,
            useTransform:false,
            infinite: true,
            rtl: dtheatro_is_rtl(),
            responsive: [
                {
                    breakpoint: 767,
                    settings: 'unslick'
                }
            ]
        };

        const slick_initialized = () => $slider.hasClass('slick-initialized');

        //window.innerWidth <= 991 && $slider.slick( options );

        $slider.slick( options );

        const resize = () => {
            //slick_initialized() && $slider.slick('unslick');
            //setTimeout(() => $slider.slick(options), 100);
        };

        $(window).on('resize orientationchange', resize );
    })();
});