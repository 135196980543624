jQuery(document).ready(function ($) {
    "use strict";
    ;( function () {
        const lightgallery = $('.flexible-gallery');
        if( lightgallery.length > 0 ) {
            lightgallery.each( function () {
                const $this_gallery = $(this);
                const gallery_id = $this_gallery.attr('id');
                let selector = gallery_id ? `#${gallery_id}` : '';
                selector += `${lightgallery.selector} .flexible__gallery-item-toggle`;
                $this_gallery.lightGallery({
                    selector, // Custom selector property instead of just child. pass this to select same element ex : .class #id
                    pager: false, // Enable/Disable pager
                    zoom: false, // Enable/Disable zoom option
                    autoplayControls: false, // Show/hide autoplay controls.
                    download: false, // Enable download button. By default download url will be taken from data-src/href attribute but it supports only for modern browsers. If you want you can provide another url for download via data-download-url. Pass false in data-download-url if you want to hide download button for the particular slide.
                    thumbnail:false,
                    animateThumb: false,
                    showThumbByDefault: false
                });
                /**
                 * Note: true - If you pass true as a parameter destroy method will destroy the gallery completely.
                 *      If parameter is undefined or false destroy will only close the gallery and plugins instance remains with the element.
                 */
                $('body').on('click touchstart', '.lg-close', () => $this_gallery.data('lightGallery').destroy(false) );
            })
        }
    })();
});